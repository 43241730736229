/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import FwFwbk from "../../common/fw/FwFwbk";
import Tzry from "../../common/fw/Tzry";
import FwTzrySelect from "../../common/fw/fwTzrySelect";
// import MyTableInfo from "./../../../components/common/Amycommon/MyTableInfo"
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqFw",
  components: {
    LCFQheader,
    FwFwbk,
    Tzry,
    FwTzrySelect
    // MyTableInfo,
  },

  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "",
      //userSelect
      single: true,
      allowSelect: true,
      showUserDialog: false,
      showDataFw: false,
      showDataffsj: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showRyDelect: false,
      showWordpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      //输入框
      wbHtmlPro: "",
      rysList: [],
      yxryList: [],
      fjsc: [],
      userid: "",
      data: {
        ngr: "",
        ngr_name: '',
        fj: []
      }
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        if (response.data.bpmSolution.name == "发文（一般通知）") {
          this.tit = "发文（一般通知）";
        } else {
          this.tit = "来文传阅";
        }
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });
    },
    async cgORxj() {
      let solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";

        //     var url = _baseUrl + "/mobile/bpm/startForm.do?solId="+solId+"&instId="+instId;
        // this.$ajax.post(url).then(res=>{
        //       this.solId = res.data.bpmSolution.solId;
        //       this.actDefId = res.data.bpmSolution.actDefId;
        //       this.boDefId = res.data.formModels[0].boDefId;
        //       this.formKey = res.data.formModels[0].formKey;
        //     })
        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          let ren = [{
            id: resData.fwr,
            text: resData.fwr_name
          }];
          this_.data.ngr = JSON.stringify(ren);
          this_.data.ngr_name = resData.ggsqr_name;
          this_.data.fwsj = resData.fwsj;
          this_.data.ffsj = resData.ffsj;
          this_.data.wjtzmc = resData.wjtzmc;
          this_.data.wjtznr = resData.wjtznr;
          this_.data.tzry = resData.tzry;
          this_.data.fffs = resData.fffs;
          this_.data.bz = resData.bz;
          this_.data.userid = resData.userid;
        });
        /***/
      } else {
        this.getUser();
      }
    },
    //获取用户信息
    getUser() {
      let ngrId = RxUtil.getCache("userID");
      let ngrName = RxUtil.getCache("userName");
      let ngr = [{
        id: ngrId,
        text: ngrName
      }];
      this.data.ngr = JSON.stringify(ngr);
    },
    //选人
    selectUserDialog(vm) {
      this.data.ngr = '';
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      console.log(json);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    showWord() {
      this.showWordpage = true;
    },
    wbHtml(item) {
      this.wbHtmlPro = item;
      this.showWordpage = false;
      console.log(this.wbHtmlPro);
    },
    onConfirmFw(timestamp) {
      this.data.fwsj = format(timestamp);
      this.showDataFw = false;
    },
    onConfirmffsj(timestamp) {
      this.data.ffsj = format(timestamp);
      this.showDataffsj = false;
    },
    showList() {
      this.showListpage = true;
    },
    //选择通知人员
    tzryFun(list) {
      let ryListName = [];
      let userid = [];
      this.rysList = list;
      this.rysList.map(item => {
        ryListName.push(item.FULLNAME_);
        userid.push(item.USER_ID_);
      });
      this.data.tzry = ryListName.join(",");
      this.userid = userid.join(",");
    },
    ryList(list) {
      this.tzryFun(list);
      this.showListpage = false;
    },
    delectRy() {
      console.log(this.rysList);
      this.yxryList = this.rysList;
      this.showRyDelect = true;
    },
    delectAfter(lists) {
      this.tzryFun(lists);
      this.showRyDelect = false;
    },
    //上传附件
    beforeRead(file) {
      // if (this.data.fj.length > 0) {
      //   let fjStr = this.onFile();
      //   if(fjStr.indexOf(file.name) == -1){
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else{
      //   return true;
      // }
      return true;
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("from", "SELF");
      formData.append("types", "");
      formData.append("sysTreeId", "");
      formData.append("id", "WU_FILE_0");
      formData.append("name", file.file.name);
      formData.append("type", file.file.type);
      formData.append("lastModifiedDate", file.file.lastModifiedDate);
      formData.append("size", file.file.size);
      formData.append("file", file.file);
      var fjObj;
      let formDataUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(formDataUrl, formData).then(res => {
        fjObj = {};
        fjObj.fileId = res.data.data[0].fileId;
        fjObj.fileName = res.data.data[0].fileName;
        fjObj.totalBytes = res.data.data[0].totalBytes;
        this.fjsc.push(fjObj);
      });
    },
    beforeDelete(file) {
      console.log('beforeDelete', file);
      for (let i = 0; i < this.fjsc.length; i++) {
        if (file.file.name == this.fjsc[i].fileName) {
          this.fjsc.splice(i, 1);
        }
      }
      console.log(this.fjsc, this.datas.fj);
      return true;
    },
    // onFile(){
    //   let fjObj = {};
    //   let fj = [];
    //   this.data.fj.map((item)=>{
    //     fjObj = {};
    //     fjObj.fileId = item.file.lastModified;
    //     fjObj.fileName = item.file.name;
    //     fjObj.totalBytes = item.file.size;
    //     fj.push(fjObj);
    //   });
    //   return JSON.stringify(fj);
    // },

    datas() {
      var ngr = JSON.parse(this.data.ngr);
      console.log(ngr);
      // var wjtznr = JSON.stringify(this.wbHtmlPro);
      let fj = JSON.stringify(this.fjsc);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            fwr: ngr[0].id,
            fwr_name: ngr[0].text,
            fwsj: formatHMT(this.data.fwsj),
            wjtzmc: this.data.wjtzmc,
            wjtznr: this.data.wjtznr,
            //this.wbHtmlPro,
            tzry: this.data.tzry,
            fj: fj,
            fffs: this.data.fffs,
            ffsj: formatHMT(this.data.ffsj),
            bz: this.data.bz,
            userid: this.userid
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      console.log(jsonData);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.ngr) || this.iN(this.data.fwsj) || this.iN(this.data.wjtzmc) || this.iN(this.data.wjtznr) || this.iN(this.data.tzry) || this.iN(this.data.fffs) || this.iN(this.data.ffsj)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      //return;
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};